import React from 'react';
import Logo from '../assets/logos/logo3.png';


function HeroText() {
  return (
    <div className="w-full h-screen px-5 flex flex-col items-center justify-evenly py-7 lg:flex-row lg:items-center lg:justify-evenly lg:gap-5">
        <div className="absolute translate-y-[-300px] lg:translate-y-[-200px] lg:translate-x-[-300px]">
            <img src={Logo} width={240} className="w-[160px] md:w-[260px]" />
        </div>
        <div>
            <p className="font-sans mb-4 text-3xl text-center font-extrabold xl:text-left lg:text-7xl bg-gradient-to-r from-blue-500 to-[#FF3C00] text-transparent bg-clip-text">Simplify Shipping <br /> Amplify Success</p>
            <p className="font-sans text-md lg:text-lg text-slate-700  font-bold text-center lg:text-left">Empowering businesse growth with seamless nationwide shipping</p>
        </div>

        <div>
            {/* <iframe src="https://lottie.host/embed/057bab1c-5f26-4a40-a086-9a5923b3fe5a/Bi15Wdlfhq.json" className="scale-[1.2] lg:scale-[1.5]"></iframe> */}
            <iframe src="https://lottie.host/embed/f14270d4-2cdc-4462-abd9-166dff6a2c13/NgxyGZfche.json" className="scale-[1.2] lg:scale-[1.5]"></iframe>
        </div>
    </div>
  )
}

export default HeroText
import React from 'react';

function AboutUs() {
  return (
    <div id="aboutus" className="w-full px-3 md:px-5">
        <p className="font-poppins mb-5 text-center text-3xl pt-12 font-extrabold bg-gradient-to-r from-blue-500 to-red-500 text-transparent bg-clip-text">Our Vision</p>
        <p className="font-sans font-[20px] md:w-[80%] text-[22px] md:text-3xl mx-auto text-center md:text-center bg-gradient-to-r from-orange-400 to-purple-500 text-transparent bg-clip-text">At ShipEez, our vision is to revolutionize the shipping landscape by making the process seamless, efficient, and worry-free. We aim to empower businesses by simplifying logistics and reducing return rates, ensuring that our clients can focus on growth while we handle the complexities of shipping. Our goal is to enhance profitability by minimizing disruptions, maximizing delivery success, and offering innovative solutions tailored to the needs of modern commerce.</p>

        <div className="md:hidden w-full mt-10 h-[290px] bg-[#E8F3F8] flex items-center justify-center">
            <iframe src="https://lottie.host/embed/8a1c89fb-2b4a-4596-8ea7-7100d5015d68/BEu6S3VSFw.json" className="w-[150px] mt-20 scale-[1.3]"></iframe>
        </div>

        <div className="mt-[50px] hidden md:flex md:items-center md:justify-center md:gap-10">

            <div className="border border-1 border-slate-300 w-[300px] rounded-xl p-4 flex flex-col items-center justify-center shadow-lg">
                <p className="bg-blue-300 text-black p-3 rounded-xl text-justify mb-5">
                    <p className="font-poppins font-bold text-sm mb-2 text-center">Advanced Technology</p>
                    ShipEez uses cutting-edge technology to make shipping easy and efficient. Our user-friendly platform is designed for simplicity, ensuring that every step, from tracking to label creation, is straightforward and hassle-free. We combine the best technology with an intuitive interface to help businesses focus on growth while we take care of the logistics.
                </p>
                <iframe src="https://lottie.host/embed/22cce3ee-9315-4745-871c-163c38e4f8f9/v9zwJEMV0K.json"></iframe>
            </div>

            <div className="border border-1 border-slate-300 w-[300px] rounded-xl p-4 flex flex-col items-center justify-center shadow-lg">
                <p className="bg-purple-300 p-3 rounded-xl text-justify mb-7">
                    <p className="font-poppins font-bold text-sm mb-2 text-center">Dedicated Support</p>
                    At ShipEez, our commitment to your success extends beyond technology. Our dedicated support team is always ready to assist you, ensuring a smooth shipping experience from start to finish. Whether you need help with an order or have questions about our services, we're here to provide prompt, reliable support, so you can focus on growing your business with confidence. 
                </p>
                <iframe src="https://lottie.host/embed/cf35b4e6-e2e5-4423-af67-41df1c728950/FraKTjSght.json"></iframe>
            </div>

            <div className="border border-1 border-slate-300 w-[300px] rounded-xl p-4 flex flex-col items-center justify-center shadow-lg">
                <p className="bg-orange-200 p-3 rounded-xl text-justify mb-7">
                    <p className="font-poppins font-bold text-sm mb-2 text-center">Growth Oriented</p>
                    ShipeEez is more than just a shipping aggregator; we’re a partner in your business’s success. By simplifying the shipping process and reducing return rates, we enable businesses to reach more customers, expand their market, and maximize profits. Our efficient logistics solutions empower you to focus on what matters most.
                </p>
                <iframe src="https://lottie.host/embed/364b8645-7b3e-42d0-b81a-48994dacdfad/2t9ARXy7mV.json"></iframe>
            </div>
        </div>
    </div>
  )
}

export default AboutUs
import { useState, useEffect } from 'react';

function ShowTimer(props) {

  const [timeLeft, setTimeLeft] = useState({});

  const launchOn = new Date("10/01/2024");

  function formatTime(ms) {
    const days = Math.floor(ms / (1000 * 60 * 60 * 24));
    const remainingMsAfterDays = ms % (1000 * 60 * 60 * 24);

    const hours = Math.floor(remainingMsAfterDays / (1000 * 60 * 60));
    const remainingMsAfterHours = remainingMsAfterDays % (1000 * 60 * 60);

    const minutes = Math.floor(remainingMsAfterHours / (1000 * 60));
    const remainingMsAfterMinutes = remainingMsAfterHours % (1000 * 60);

    const seconds = Math.floor(remainingMsAfterMinutes / 1000);

    return [
      `${String(days).padStart(2, "0")}`,
      `${String(hours).padStart(2, "0")}`,
      `${String(minutes).padStart(2, "0")}`,
      `${String(seconds).padStart(2, "0")}`,
    ];
  }

  useEffect(() => {
    const updateTimeLeft = setInterval(() => {
      let timeLeft = launchOn.getTime() - Date.now();

      let timeUnits = formatTime(timeLeft);

      setTimeLeft({
        days: timeUnits[0],
        hours: timeUnits[1],
        minutes: timeUnits[2],
        seconds: timeUnits[3],
      });
    }, 1000);

    return () => clearInterval(updateTimeLeft);
  }, []);

  return (
    <div className="relative h-[270px] bg-gradient-to-r from-blue-500 to-red-500">
        <div className="absolute top-0 w-full h-[260px] bg-white">
            <p className="font-sans font-extrabold bg-gradient-to-r from-blue-500 to-red-500 text-transparent bg-clip-text text-center text-3xl">Hang Tight!</p>
            <p className="text-center font-bold text-xl lg:text-3xl mb-5 bg-gradient-to-r from-blue-500 to-red-500 text-transparent bg-clip-text">We are launching in...</p>
            <Timer days={timeLeft.days} hours={timeLeft.hours} minutes={timeLeft.minutes} seconds={timeLeft.seconds} />
        </div>
    </div>
  );
}



function Timer(props) {
  return (
    <div className="w-[350px] lg:w-[450px] mx-auto text-5xl md:text-7xl flex gap-4">
      <span className="flex flex-col gap-2 text-red-600">
        <span>{props.days}</span>
        <span className="text-xl md:text-3xl">Days</span>
      </span>
      :
      <span className="flex flex-col gap-2 text-red-600">
        <span>{props.hours}</span>
        <span className="text-xl md:text-3xl">Hours</span>
      </span>
      :
      <span className="flex flex-col gap-2 text-red-600">
        <span>{props.minutes}</span>
        <span className="text-xl md:text-3xl">Mins</span>
      </span>
      :
      <span className="flex flex-col gap-2 text-red-600">
        <span>{props.seconds}</span>
        <span className="text-xl md:text-3xl">Secs</span>
      </span>
    </div>
  );
}


export default ShowTimer;
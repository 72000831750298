import React, { useEffect } from "react";

import Favicon from "../assets/logos/favicon.png";

import HeroText from "../components/HeroText";
import ShowTimer from "../components/Timer";
import AboutUs from "../components/AboutUs";
import Navbar from "../components/Navbar";
import Team from '../components/Team';
import Footer from "../components/Footer";

function Home() {

  useEffect(() => {
    const favicon = document.getElementById("favicon");

    favicon.setAttribute("href", Favicon);
  }, []);


  return (
      <div className="scroll-smooth">
        <Navbar />
        <HeroText />
        <ShowTimer />
        <AboutUs />
        <Team />
        <Footer />
      </div>
  );
}


export default Home;

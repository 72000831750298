import React, { useState, useEffect, useRef } from 'react'

import Logo from '../assets/logos/logo2.png';


function Navbar() {

    const [isNavOpen, setNavOpen] = useState(false);

    const hamButton = useRef(null);

    useEffect(() => {
        document.addEventListener("click", (event) => {
            if (event.target.contains(hamButton.current)) {

                console.log("hi", isNavOpen);
                setNavOpen(false);
                return;
            }
        });
    }, [])

    return (
        <div className="w-full h-[60px] bg-gradient-to-br px-5 flex items-center justify-between md:justify-between bg-white">

            <img src={Logo} width={110} className="hidden md:inline-block" />

            <ul className="hidden md:flex md:gap-5 md:items-center md:justify-center">
                <a href="#aboutus" className="text-md font-bold font-sans text-slate-700">About Us</a>
                <a href="#team" className="text-md font-bold font-sans text-slate-700">Our Team</a>
            </ul>

        </div>
    )
}

export default Navbar
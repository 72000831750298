import React from 'react';

import Shoaib from '../assets/images/shobi.png';
import Zee from '../assets/images/zeeshu.png';
import Faisal from '../assets/images/faisu.png'

import Linkedin from '../assets/icons/linkedin.png';
import Twitter from '../assets/icons/twitter.png';

function Team() {
  return (
    <div id="team" className="w-full mt-20 text-3xl text-bold text-slate-700 font-bold mb-10">
        <p className="text-center ">Our Professional Team</p> 
        <p className="text-sm text-center">Meet the best team in the world !</p>

        <div className="mt-20 flex flex-col md:flex-row gap-[100px] md:gap-[90px] items-center justify-center">

            <div className="relative w-[300px] h-[300px] bg-slate-100/60 rounded-xl p-6 shadow-lg border border-1 border-slate-700/30 flex flex-col items-center justify-center">
                <img src={Zee} className="rounded-2xl w-[120px] md:w-[150px] absolute translate-y-[-140px]" />
                <p className="text-[20px] font-normal mt-9">Zeeshan Bhat</p>
                <p className="text-[15px]">CEO</p>
                <p className="text-[12px] font-normal leading-tight">At ShipEez, we envision a world where shipping is no longer a barrier to business growth. Our mission is to simplify and streamline the entire process, allowing businesses to expand effortlessly.</p>
                <p className="w-full mt-3 flex gap-2 items-center justify-start relative translate-y-[30px]">
                    <a target="_blank" href="https://www.linkedin.com/in/zeeshan-rashid-756299323/"><img src={Linkedin} width={20} /></a>
                    {/* <a target="_blank" href=""><img src={Twitter} width={20} /></a> */}
                </p>
            </div>

            <div className="relative w-[300px] h-[300px] bg-slate-100/60 rounded-xl p-6 shadow-lg border border-1 border-slate-700/30 flex flex-col items-center justify-center">
                <img src={Shoaib} className="rounded-2xl w-[120px] md:w-[150px] absolute translate-y-[-140px]" />
                <p className="text-[20px] font-normal mt-9">Shoaib Wani</p>
                <p className="text-[15px]">CTO</p>
                <p className="text-[12px] font-normal leading-tight">Technology is at the heart of ShipEez. We’re committed to leveraging the best tools to turn complex logistics into a straightforward, user-friendly process, ensuring that every delivery contributes to your success.</p>
                <p className="w-full mt-3 flex gap-2 items-center justify-start relative translate-y-[30px]">
                    <a target="_blank" href="https://www.linkedin.com/in/shoaibhassanwani"><img src={Linkedin} width={20} /></a>
                    <a target="_blank" href="https://x.com/beast_logics"><img src={Twitter} width={20} /></a>
                </p>
            </div>

            <div className="relative w-[300px] h-[300px] bg-slate-100/60 rounded-xl p-6 shadow-lg border border-1 border-slate-700/30 flex flex-col items-center justify-center">
                <img src={Faisal} className="rounded-2xl w-[120px] md:w-[150px] absolute translate-y-[-140px]" />
                <p className="text-[20px] font-normal mt-9">Faisal Rasheed</p>
                <p className="text-[15px]">COO</p>
                <p className="text-[12px] font-normal leading-tight">We’re dedicated to creating a seamless shipping experience that boosts efficiency and reliability. By focusing on operational excellence, we help businesses thrive in a competitive market.</p>
                <p className="w-full mt-3 flex gap-2 items-center justify-start relative translate-y-[30px]">
                    <a target="_blank" href="https://www.linkedin.com/in/faisalrasheedlone"><img src={Linkedin} width={20} /></a>
                    {/* <a target="_blank" href=""><img src={Twitter} width={20} /></a> */}
                </p>
            </div>
        </div>
    </div>
  )
}

export default Team
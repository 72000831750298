import React from 'react'

function Footer() {
  return (
    <div className="h-[40px] bg-blue-700 font-mono text-center flex items-center justify-center text-white">
        All Rights Reserved - Shipeez 2024
    </div>
  )
}

export default Footer